import Container from "@src/components/Container";
import React from "react";
import ReactMarkdown from "react-markdown";
import SEO from "@src/components/SEO";

const CONTENT = `
**2025 Mojo Madness Contest**
**Official Contest Rules**

**NO PURCHASE OR PAYMENT OF ANY MONEY IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE THE CHANCES OF WINNING. VOID WHERE PROHIBITED OR RESTRICTED BY LAW OR WHERE REGISTRATION REQUIRED. THIS CONTEST IS SUBJECT TO ALL FEDERAL, STATE AND LOCAL LAWS.**

**SPONSOR:** ClassDojo, Inc., located at 735 Tehama Street, San Francisco, California 94103 (“**Sponsor**”).

**AGREEMENT TO OFFICIAL RULES:** By participating in the Contest (as defined below), you expressly agree to (i) be bound by these official rules (“**Official Rules**”) and the decisions of the Sponsor, including without limitation decisions regarding eligibility, winner, and the interpretation of terms used in these Official Rules, which are final and binding in all aspects, and (ii) receive marketing communications regarding ClassDojo products, services, and events. By entering, you represent that you have the right to enter and agree to the Official Rules and that entering would not be prohibited by your school or employer.

**CONTEST PERIOD:** The 2025 Mojo Madness Contest (“**Contest**”) sponsored by Sponsor begins on March 10th, 2025 at 9am PT and ends on April 7th, 2025, at 11:59 pm PT (“**Contest Period**”). All times in these Official Rules are Pacific Time Zone. Sponsor’s computer will be the official clock for the Contest. All entries must be received by March 17th, 2025 at 5:00 PT to be eligible to enter the Contest or win a prize.

**ELIGIBILITY:** The Contest is open to individuals (“**Entrant**” or “**you**”) that live in the United States who are 18 years of age or older or who have reached the age of majority in their jurisdiction of residence as of the date of entry who are teachers that are registered ClassDojo users as of the beginning of the Contest Period at an accredited educational institution (a “**Participating Teacher**”).  Employees, contractors, consultants, officers, directors, and agents (and their immediate families and household members regardless of where they live), or members of the same households, whether related or not, of Sponsor, its parents, divisions, subsidiaries, agents, promotional partners, advertising/promotion agencies, and prize suppliers and deliverers (“**Contest Entities**”) are not eligible to win any prize. Failure to meet the eligibility requirements will result in an entry being invalid and forfeiture of any prize. Sponsor reserves the right to disqualify any Entrant that Sponsor determines could potentially reflect negatively on Sponsor’s image. Sponsor’s decisions regarding eligibility and all other aspects of the Contest are final and binding subject to applicable laws.

**PRIVACY:** Sponsor collects personal information from you when you enter the Contest. The information collected is subject to Sponsor’s privacy policy (as may be amended from time to time), which can be found at [https://www.classdojo.com/privacy/](https://www.classdojo.com/privacy/) (“**Privacy Policy**”). In the event of any conflict between these Official Rules and Sponsor’s Privacy Policy, the terms and conditions of these Official Rules shall prevail. The personal information of participants and winner may be provided to corporate affiliates of the Sponsor, third parties assisting in the conduct of the Contest, including with Contest Entities, and for publicity purposes surrounding the Contest. If the personal information requested is not provided, you may not participate in the Contest. By participating in the Contest, you hereby agree to Sponsor’s collection and usage of your personal information as described and in accordance with the Privacy Policy and acknowledge that you have read and accepted the Privacy Policy.

**HOW TO ENTER:** To enter the Contest, you must go to the Contest page at [https://www.classdojo.com/mojo-madness](https://www.classdojo.com/mojo-madness) and submit a bracket predicting the winners of the 16-team head-to-head Mojo Madness matches (15 head-to-head matches in total).  Each 2025 Mojo Madness Contest Submission must include the name and email of the Entrant.  If you have questions about how to enter, please reach out to submissions@classdojo.com.

In the event of a dispute as to the identity of Entrants or winner, Sponsor shall make the final determination as to identity. You agree that, as between you and Sponsor, Sponsor has the sole right to decide all matters and disputes arising from the Contest and that all decisions of Sponsor related to the Contest are final and binding. Sponsor reserves the right to disqualify and prosecute to the fullest extent permitted by law any Entrant or winner who it suspects has tampered with the Contest, the entry or participation process, violated the Official Rules, or acted in an unsportsmanlike or disruptive manner.

**PRIZE DETAILS:** The prizes described below will be awarded after completion of the Contest Period and after determination of winner by Sponsor (“**Prizes**”). No substitution, assignment or transfer of Prizes is permitted, except by Sponsor, who has the right to substitute Prizes with others of comparable or greater value. Sponsor is not responsible for any errors or delays in the awarding of Prizes.

Grand Prize:

* Sponsor will purchase the winning teacher’s existing Amazon “Wishlist” goods (as of the beginning of the Contest Period) for school-related supplies, up to [$1000]
* Sponsor will provide limited edition Mojo jerseys for the teacher’s class

Approximate retail value (“**ARV**”) all prizes is ~$3500.

**PRIZE CONDITIONS**: Sponsor is not responsible for Prizes that are unable to be awarded. Winner may be responsible for any costs and expenses not listed herein. Prizes may be reported as income to a winner and all federal, state/provincial/territorial, and local taxes, fees and surcharges and taxes (whether foreign or domestic, and including income, sales, and import taxes) on Prizes are the sole responsibility of the Prize winners. Please consult a tax professional to discuss your particular situation. Winner may be required to complete required tax forms or other documents requested within the time period stipulated by Sponsor. Sponsor reserves the right to amend or cancel the Contest for any reason, including if the fairness of the Contest is compromised. Proof of identity, employment at a K-12 educational institution, age and/or legal residence may be required to claim a Prize. Failure to comply with any of the requirements set forth in the Official Rules may result in disqualification or forfeiture of a Prize.

**WINNER SELECTION AND ANNOUNCEMENT:** There will be 1 grand prize winner selected.  The grand prize winner will be the person who successfully picks the most winning games in their bracket.  In the event of a tie among Entrants, the grand prize winner will be randomly selected among all Entrants who tied for the highest point total.  Winner will be announced at [https://www.classdojo.com/mojo-madness](https://www.classdojo.com/mojo-madness).

Winner is deemed to be a potential winner pending verification, including without limitation, verification of eligibility, compliance with the terms and conditions of the Contest, execution of an affidavit of eligibility, publicity release and license grant, and completion of any required documents as determined by Sponsor, at Sponsor’s sole discretion. Sponsor will not be liable for any potential Prize notification that is lost, intercepted, or not received by any potential winner for any reason. If a potential winner cannot be contacted within five (5) days after the first attempt to contact the winner, the Sponsor may select an alternate potential winner from the remaining eligible entries.

**PUBLICITY RELEASE AND ADDITIONAL AGREEMENTS**: Winner may be required to sign an affidavit of eligibility, liability release, and a publicity release that, among other things, will allow Sponsor to use the winner’s name, photograph, likeness, social media posts (including video or photo), voice, prize information, opinions, and biographical information for publicity and promotional purposes without further compensation where permitted by law, including in connection with promotion winner on Sponsor’s website. Except where prohibited, participation in the Contest constitutes your consent to Sponsor’s use of your name, photograph, likeness, social media post (including video or photo), voice, prize information, opinions, and biographical information, for promotional purposes in any media without further payment or consideration.

**REPRESENTATION AND WARRANTIES AND CONDUCT:** BY ENTERING AND PARTICIPATING IN THIS CONTEST, YOU: (A) REPRESENT AND WARRANT THAT ALL INFORMATION PROVIDED BY YOU IN CONNECTION WITH THE CONTEST IS TRUE, ACCURATE, AND COMPLETE; (B) HAVE FULL AUTHORITY TO GRANT THE RIGHTS SET FORTH IN THESE OFFICIAL RULES; AND (C) AGREE TO COMPLY WITH ALL FEDERAL, STATE, AND LOCAL LAWS AND REGULATIONS. Any attempt by Entrant or any other individual to deliberately damage any internet site or undermine the legitimate operation of the Contest is a violation of these Official Rules and may violate criminal and civil laws. Should Sponsor believe or become aware that such an attempt has been, is being, or will be made, Sponsor reserves the right to seek remedies and damages from any responsible Entrant(s) and other responsible individual(s) in the attempted damage to the fullest extent permitted by law, including without limitation criminal prosecution.

**WARRANTY DISCLAIMER:** EACH PRIZE IS PROVIDED (OR WILL BE AWARDED) "AS IS" AND WITHOUT WARRANTY OF ANY KIND, AND SPONSOR AND CONTEST ENTITIES HEREBY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT), CONCERNING ANY PRIZE FURNISHED OR MADE AVAILABLE IN CONNECTION WITH THE CONTEST.
**GENERAL RELEASE:** YOU HEREBY HOLD SPONSOR, CONTEST ENTITIES, AUTHORIZED PARTIES, THEIR AFFILIATES, AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, AND SHAREHOLDERS (COLLECTIVELY “**RELEASEES**”) HARMLESS FROM AND AGAINST ANY AND ALL LOSSES, DAMAGES, RIGHTS, CLAIMS, AND ACTIONS OF ANY KIND ARISING IN WHOLE OR IN PART, DIRECTLY OR INDIRECTLY, FROM THE CONTEST, PARTICIPATION IN ANY CONTEST RELATED ACTIVITY, OR RESULTING DIRECTLY OR INDIRECTLY FROM THE ACCEPTANCE, POSSESSION, USE OR MISUSE OF ANY PRIZE AWARDED IN CONNECTION WITH THE CONTEST, INCLUDING WITHOUT LIMITATION PERSONAL INJURY, DEATH AND/OR PROPERTY DAMAGE, AS WELL AS CLAIMS BASED ON PUBLICITY RIGHTS, DEFAMATION AND/OR INVASION OF PRIVACY AND THE COLLECTION, USE AND/OR SHARING BY SPONSOR AND CONTEST ENTITIES OF PERSONALLY IDENTIFIABLE INFORMATION OF ENTRANTS, OR FOR ANY PRINTING, PRODUCTION, TYPOGRAPHICAL OR HUMAN ERROR.
RELEASEES ASSUME NO RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, OR DELAY IN OPERATION OR TRANSMISSION OR ALTERATION OF ENTRIES. RELEASEES ARE NOT RESPONSIBLE FOR ANY PROBLEMS WITH OR TECHNICAL MALFUNCTION OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE SYSTEMS, SERVERS OR PROVIDERS, COMPUTER EQUIPMENT, SOFTWARE, FAILURE OF ANY ENTRY TO BE RECEIVED ON ACCOUNT OF TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET OR AT ANY WEBSITE, HUMAN ERRORS OF ANY KIND, OR ANY COMBINATION THEREOF, INCLUDING ANY INJURY OR DAMAGE TO ENTRANTS' OR ANY OTHER PERSONS' COMPUTERS, WIRELESS DEVICES OR OTHER EQUIPMENT RELATED TO OR RESULTING FROM PARTICIPATION, UPLOADING OR DOWNLOADING OF ANY MATERIALS RELATED TO THE CONTEST, OR IF FOR ANY REASON THE CONTEST IS NOT CAPABLE OF BEING CONDUCTED AS PLANNED, INCLUDING BUT NOT LIMITED TO INFECTION BY COMPUTER VIRUSES, BUGS, TAMPERING, UNAUTHORIZED INTERVENTION, FRAUD, TECHNICAL FAILURES OR OTHER CAUSES BEYOND THE CONTROL OF RELEASEES.

**LIMITATION OF LIABILITY:** IN NO EVENT WILL RELEASEES BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND, INCLUDING WITHOUT LIMITATION, ATTORNEYS FEES, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING OUT OF OR RELATED TO ANY ACCESS TO AND/OR USE OF ANY WEBSITE, THE DOWNLOADING FROM AND/OR PRINTING MATERIAL DOWNLOADED FROM ANY WEBSITE, THE REMOVAL FROM ANY WEBSITE OF, OR DISCONTINUATION OF ACCESS TO, ANY MATERIALS, OR ANY HARM RESULTING FROM THE ACCEPTANCE, POSSESSION, USE OR MISUSE OF, OR ATTENDANCE OR PARTICIPATION IN, ANY PRIZE AWARDED IN CONNECTION WITH THE CONTEST. THE RELEASEES’ LIABILITY WILL BE LIMITED TO THE COST OF ENTERING AND PARTICIPATING IN THE CONTEST.

**DISQUALIFICATION/FORCE MAJEURE:** Sponsor reserves the right, at any time and in its sole discretion, to disqualify and/or deem ineligible to participate in this Contest, any individual who Sponsor believes to be: (A) tampering with the entry process or the operation of the Contest or any website, (B) acting in violation of these Official Rules or Sponsor’s Privacy Policy or other terms, conditions or guidelines; (C) acting in bad faith, an unsportsmanlike or disruptive manner;(C) disrupting or undermining the legitimate operation of the Contest; or (D) annoying, abusing, threatening or harassing any other person. Sponsor reserves the right to seek damages and other remedies from any such person to the fullest extent permitted by law.

In the event an insufficient number of eligible entries are received and/or Sponsor is prevented from awarding prizes or continuing the Contest as contemplated in these Official Rules by any event beyond its control (“**Force Majeure Event**”), including but not limited to fire, flood, natural or man-made epidemic of health of other means, earthquake, explosion, labor dispute or strike, act of God or public enemy, satellite or equipment failure, riot or civil disturbance, terrorist threat or activity, war (declared or undeclared) or any federal state or local government law, order, or regulation, public health crisis, order of any court or jurisdiction, then Sponsor will have the right to modify, suspend, or terminate the Contest. If the Contest is terminated before the designated end date, Sponsor will (if possible) select the winner from all eligible, non-suspect entries received as of the date of the Force Majeure Event giving rise to the termination. Inclusion in such selection process will be each Entrant’s sole and exclusive remedy under such circumstances. Only the type and quantity of Prizes described in these Official Rules will be awarded.

**GENERAL CONDITIONS:** Sponsor reserves the right to shorten, extend, modify, or cancel the Contest, in its sole discretion, at any time and without notice, even though such action may affect your ability to win a prize. Sponsor is not responsible for any typographical or other error in printing or description of the Contest. Entries are void if unreadable, inaccurate, and incomplete, mutilated, tampered with, forged, mechanically reproduced, irregular in any way or otherwise not in compliance with these Official Rules. Although Sponsor attempts to ensure the integrity of the Contest, Sponsor and Contest Entities are not responsible for the actions of Entrants or other individuals in connection with the Contest, including Entrants’ or other individuals’ attempts to circumvent the Official Rules or otherwise interfere with the administration, security, fairness, integrity or proper conduct of the Contest. In the event of any conflict between any details contained in these Official Rules and details contained in any Contest advertising materials, the details of the Contest as set forth in these Official Rules shall prevail.

**SEVERABILITY:** If any provision of these Official Rules is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Official Rules and shall not affect the validity and enforceability of any remaining provisions. Headings and captions are used in these Official Rules solely for convenience of reference and shall not be deeded to affect in any manner the meaning or intent of these Official Rules or any provision thereof.

**DISPUTES**: Except where prohibited, all disputes relating to the Contest will be exclusively resolved under confidential binding arbitration held in San Francisco, California. All disputes will be resolved in accordance with the Rules of JAMS, applying California law, without regard to conflicts of law principles. You and Sponsor agree to submit to the personal and exclusive jurisdiction of the federal and California state courts in San Francisco County for purposes of enforcing any arbitration award. Notwithstanding the foregoing, Sponsor may seek injunctive or other equitable relief, from a court of competent jurisdiction. You and Sponsor agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. You and Sponsor agree that any cause of action arising out of or related to the Contest must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.

**CONTEST RESULTS:** To request a list of winner’s names or a copy of the Official Rules, send a self-addressed, stamped envelope to ClassDojo, Inc. Attn: Mojo Madness Contest, ClassDojo HQ, 735 Tehama Street, San Francisco, California 94103, or send an email to submissions@classdojo.com. Requests must be received within end of the Contest Period. Vermont residents may omit postage.
`;

const Terms = () => {
  return (
    <Container>
      <SEO title="Mojo Madness Contest Official Contest Rules" />
      <div css={{ marginTop: 50, marginBottom: 50 }}>
        <ReactMarkdown>{CONTENT}</ReactMarkdown>;
      </div>
    </Container>
  );
};

export default Terms;
